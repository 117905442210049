import React, { useState, useEffect } from "react"
import { useTheme } from "@material-ui/core/styles"
import MobileStepper from "@material-ui/core/MobileStepper"
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import SwipeableViews from "react-swipeable-views"
import { virtualize, bindKeyboard } from "react-swipeable-views-utils"
import nunkkiArrow from "../images/nunkkiArrow.png"

import { colors, boxShadow } from "./globalStyle"
import styled from "styled-components"
import GlobalHeading from "./globalHeading"

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews))

const StyledDiv = styled.div`
  background: ${colors.white};
  box-shadow: ${boxShadow.medium};
  padding: 5rem 7rem;
  width: 100%;
  height: 500px;

  .img-section {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 100vh;
    width: 11rem;
    margin-left: -7rem;
    overflow: hidden;

    img {
      width: 7rem;
      margin: -0.2rem;
      object-fit: contain;
      -webkit-filter: drop-shadow(1px 1px 99px rgba(8, 47, 67, 0.16));
      filter: drop-shadow(1px 1px 99px rgba(8, 47, 67, 0.16));
      &:nth-child(2n) {
        transform: rotate(180deg);
      }
      &:nth-child(1) {
        ${props =>
    props.activeStep === 0
      ? "  margin-left: 2rem; transform: scale(0.9); transition: .3s ease-in-out;"
      : ""}
        ${props =>
    props.activeStep === 1 ? "  transition: .3s ease-in-out;" : ""}
      }
      &:nth-child(2) {
        ${props =>
    props.activeStep === 1
      ? ` margin-left: 2rem; 
            transform: scale(0.9) rotate(180deg);
            transition: .3s ease-in-out;
`
      : ""}
        ${props =>
    props.activeStep === 0 || props.activeStep === 2
      ? "  transition: .3s ease-in-out;"
      : ""}
      }
      &:nth-child(3) {
        ${props =>
    props.activeStep === 2
      ? "  margin-left: 2rem; transform: scale(0.9); transition: .3s ease-in-out;"
      : ""}
        ${props =>
    props.activeStep === 1 || props.activeStep === 3
      ? "  transition: .3s ease-in-out;"
      : ""}
      }
      &:nth-child(4) {
        ${props =>
    props.activeStep === 3
      ? "  margin-left: 2rem; transform: scale(0.9) rotate(180deg); transition: .3s ease-in-out;"
      : ""}
        ${props =>
    props.activeStep === 2 ? "  transition: .3s ease-in-out;" : ""}
      }
    }
  }

  section {
    main {
      z-index: 200;
      display: flex;
      margin: 3rem auto;
      button {
        margin: auto 2rem;
        padding: 2rem;
        background: ${colors.white};
        border: 0;
        cursor: pointer;
      }
    }

    max-width: 1000px;
    margin: auto;

    .MuiMobileStepper-root {
      background: ${colors.white};

      button {
        background: ${colors.white};
        border: 0;
      }
    }
    .stepper-label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      p {
        font-family: Montserrat;
        font-size: 1rem;
        line-height: 1.95rem;
        margin: 0;
      }
    }
  }

  .mobile-slider {
    padding: 2rem 5rem;
    padding-bottom: 0 !important;
    font-family: Montserrat;
    font-size: 0.8rem;
    .MuiMobileStepper-dots {
      opacity: 0.5;
    }
    .MuiMobileStepper-dotActive {
      background-color: ${colors.primary};
      opacity: 1;
    }
  }

  .img-section-mobile {
    position: relative;
    display: none;
    margin: 0 auto;
    width: 30vw !important;
    height: 0vh !important;
    max-width: 280px;
    max-height: 30px;

    img {
      margin: -0.55rem;
      object-fit: contain;
      -webkit-filter: drop-shadow(1px 1px 99px rgba(8, 47, 67, 0.16));
      filter: drop-shadow(1px 1px 99px rgba(8, 47, 67, 0.16));
      opacity: 0.3;
      transform: rotate(90deg);
      &:nth-child(2n) {
        transform: rotate(-90deg);
      }
      &:nth-child(1) {
        ${props =>
    props.activeStep === 0
      ? "  margin-top: -1rem; opacity: 1; transform: scale(0.9) rotate(90deg); transition: .3s ease-in-out;"
      : ""}
        ${props =>
    props.activeStep === 1 ? "  transition: .3s ease-in-out;" : ""}
      }
      &:nth-child(2) {
        ${props =>
    props.activeStep === 1
      ? ` margin-top: -1rem; opacity: 1; 
            transform: scale(0.9) rotate(-90deg);
            transition: .3s ease-in-out;
`
      : ""}
        ${props =>
    props.activeStep === 0 || props.activeStep === 2
      ? "  transition: .3s ease-in-out;"
      : ""}
      }
      &:nth-child(3) {
        ${props =>
    props.activeStep === 2
      ? "  margin-top: -1rem; opacity: 1; transform: scale(0.9) rotate(90deg); transition: .3s ease-in-out;"
      : ""}
        ${props =>
    props.activeStep === 1 || props.activeStep === 3
      ? "  transition: .3s ease-in-out;"
      : ""}
      }
      &:nth-child(4) {
        ${props =>
    props.activeStep === 3
      ? "  margin-top: -1rem; opacity: 1; transform: scale(0.9) rotate(-90deg); transition: .3s ease-in-out;"
      : ""}
        ${props =>
    props.activeStep === 2 ? "  transition: .3s ease-in-out;" : ""}
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 3rem 5rem !important;
    padding-bottom: 1rem !important;
    height: auto !important;
    img {
      width: 5rem !important;
    }
    .stepper-label p {
      font-size: 0.8rem !important;
    }
  }
  @media (max-width: 910px) {
    padding: 3rem 3rem !important;
  }
  @media (max-width: 768px) {
    padding: 2rem 2rem !important;
  }
  @media (max-width: 468px) {
    .mobile-slider {
      padding: 2rem 0rem;
    }
    .img-section-mobile {
      img {
        margin: 0 !important;
      }
    }
  }
`

const Slider = ({ siteTitle }) => {
  const [activeStep, setActiveStep] = useState(0)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener("resize", updateWindowDimensions)

    return () => {
      window.removeEventListener("resize", updateWindowDimensions)
    }
  })

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth)
  }

  const tutorialSteps = [
    {
      label: ` ... your business is a golf course and you need a weather station
      paired with an amazing website to display real-time metrics like air
      temperature, wind speed, and precipitation data to your customers….
      done!`,
    },
    {
      label: ` ... your business stocks a large inventory of products and you need an
      automated system to track your inventory flow, complete with barcode
      scanners and software analytics to inform you when you need to
      resupply….done!`,
    },
    {
      label: ` ... your business is a hair salon, and you’d like a company website
      with an automated booking system for customers… done!`,
    },
    {
      label: `... anything… done!`,
    },
  ]
  const maxSteps = tutorialSteps.length
  const theme = useTheme()

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStepChange = step => {
    setActiveStep(step)
  }

  const slideRenderer = params => {
    const { index, key } = params

    switch (index) {
      case 0:
        return (
          <div key={key}>
            ... your business is a golf course and you need a weather station
            paired with an amazing website to display real-time metrics like air
            temperature, wind speed, and precipitation data to your customers….
            done!
          </div>
        )

      case 1:
        return (
          <div key={key}>
            ... your business stocks a large inventory of products and you need
            an automated system to track your inventory flow, complete with
            barcode scanners and software analytics to inform you when you need
            to resupply….done!
          </div>
        )

      case 2:
        return (
          <div key={key}>
            ... your business is a hair salon, and you’d like a company website
            with an automated booking system for customers… done!
          </div>
        )
      case 3:
        return <div key={key}>... anything… done!</div>
      default:
        break
    }
  }

  return (
    <StyledDiv activeStep={activeStep}>
      <div>
        <GlobalHeading
          primary={"inspirations"}
          secondary={"Let’s imagine..."}
          subsection={"Let’s imagine"}
        />
        <section>
          {width >= 900 && (
            <main>
              <button onClick={handleBack} disabled={activeStep === 0}>
                <KeyboardBackspace />
              </button>
              {/**
          <button>
            <img src={arrow} />
          </button> */}

              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {tutorialSteps.map((step, index) => (
                  <div key={step.label} className="stepper-label">
                    {Math.abs(activeStep - index) <= 2 ? (
                      <p>{step.label}</p>
                    ) : null}
                  </div>
                ))}
              </SwipeableViews>
              <button
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                <KeyboardBackspace style={{ transform: "rotate(180deg)" }} />
              </button>
            </main>
          )}

          {width < 900 && (
            <div className="mobile-slider">
              <VirtualizeSwipeableViews
                index={activeStep}
                slideRenderer={slideRenderer}
              />

              <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                      )}
                  </button>
                }
                backButton={
                  <button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                      )}
                  </button>
                }
              >
                {activeStep}
              </MobileStepper>
              <div className="img-section-mobile">
                <img src={nunkkiArrow} alt="arrow"></img>
                <img src={nunkkiArrow} alt="arrow"></img>
                <img src={nunkkiArrow} alt="arrow"></img>
                <img src={nunkkiArrow} alt="arrow"></img>
              </div>
            </div>
          )}
        </section>
      </div>
      {width > 900 && (
        <div className="img-section">
          <img src={nunkkiArrow} alt="arrow"></img>
          <img src={nunkkiArrow} alt="arrow"></img>
          <img src={nunkkiArrow} alt="arrow"></img>
          <img src={nunkkiArrow} alt="arrow"></img>
        </div>
      )}
    </StyledDiv>
  )
}

Slider.propTypes = {}

Slider.defaultProps = {}

export default Slider
