import React from "react"
import pic01 from "../images/01.png"
import pic02 from "../images/02.png"
import pic03 from "../images/03.png"
import pic04 from "../images/04.png"
import pic05 from "../images/05.png"
import pic06 from "../images/06.png"
import servicesBg from "../images/services_bg.png"
import servicesBgMobile from "../images/services_background.png"
import { colors } from "./globalStyle"

import styled from "styled-components"
import GlobalHeading from "./globalHeading"
const StyledDiv = styled.div`
  background-image: url(${servicesBg});
  background-position: -20vw 10vh;
  background-size: 120vw;
  background-repeat: no-repeat;
  background-attachment: fixed;
  .box {
    display: flex;
  }

  #sticky-header {
    position: sticky;
    position: -webkit-sticky;
    z-index: 1000;
    top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 3rem 7rem;
    padding-bottom: 2rem;

    background: #f7f7f8;
  }

  #sticky {
    padding-left: 3rem;
    position: sticky;
    position: -webkit-sticky;
    height: 50vh;
    top: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .extra,
  #wrapper {
    padding: 1.5rem 7rem;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: auto;

    .services-title {
      text-transform: uppercase;
      font-size: 1.5rem;
      color: ${colors.blueGrey};
      font-family: Baloo;
      padding-bottom: 1rem;
    }

    .content {
      padding-left: 10vw;
      padding-rigth: 20px;
      max-width: 70%;
      width: 70%;
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 50px;
        &:nth-child(2n) {
          flex-direction: row;
          img {
            padding-right: 10%;
          }
        }
        &:nth-child(2n + 1) {
          flex-direction: row-reverse;
          img {
            padding-left: 10%;
          }
        }
      }

      p {
        font-family: Montserrat;
        font-size: 0.85rem;
        line-height: 1.6rem;
        margin: 0;
      }
      h3.services-title {
        font-size: 1rem;
        margin-bottom: 0.3rem;
        letter-spacing: 0.3px;
        opacity: 0.8;
      }
      img {
        height: 150px;
        max-height: 150px;
        width: auto;
        padding: 0;
        margin: 0;
      }
    }
  }
  #wrapper {
    height: 1600px; //1500 + 100 px reserve
  }

  @media (max-width: 1400px) {
    #content > div {
      margin-bottom: 70px !important;
      img {
        height: 130px !important;
        max-height: 130px !important;
      }
    }
    #wrapper {
      height: 1900px !important;
    }
  }

  @media (max-width: 1281px) {
    #content > div {
      margin-bottom: 100px !important;
      img {
        height: 70px !important;
        max-height: 70px !important;
      }
    }
    #wrapper {
      height: 2200px !important;
    }
    #sticky .services-title {
      padding-bottom: 0 !important;
    }
  }

  @media (max-width: 1220px) {
    #content > div {
      display: flex;
      flex-direction: column !important;
      align-items: flex-start !important;
      margin-bottom: 50px !important;
      img {
        padding: 0 !important;
        padding-bottom: 0.8rem !important;
        height: 90px !important;
        max-height: 90px !important;
      }
    }
    #wrapper {
      height: 2300px !important;
    }
    #sticky .services-title {
      font-size: 1.2rem !important;
    }
  }
  @media (max-width: 1024px) {
    #sticky-header {
      padding: 3rem 5rem !important;
      padding-bottom: 1rem !important;
    }
    #wrapper {
      padding: 1.5rem 5rem !important;
    }
  }
  @media (max-width: 1000px) {
    #sticky .services-title {
      font-size: 1rem !important;
    }
  }
  @media (max-width: 910px) {
    background-position: -90vw -40vh;
    background-size: 220vw;
    #content {
      padding-left: 0 !important;
      width: 100% !important;
      max-width: 100% !important;

      div {
        align-items: center !important;
        margin-bottom: 50px;
        &:nth-child(2n) {
          flex-direction: row !important;
          img {
            padding-right: 10% !important;
          }
        }
        &:nth-child(2n + 1) {
          flex-direction: row-reverse !important;
          img {
            padding-left: 10% !important;
          }
        }
        .services-title {
          font-size: 1.3rem !important;
        }
      }
    }
    #sticky {
      display: none;
    }
    #wrapper {
      height: auto !important;
    }
    #sticky-header {
      padding: 2rem 3rem !important;
    }
    #wrapper {
      padding: 2rem 3rem !important;
    }
  }
  @media (max-width: 768px) {
    background-image: url(${servicesBgMobile});
    background-position: -90vw -60vh;
    background-size: 280vw;

    #sticky-header {
      padding: 2rem 1rem !important;
      padding-bottom: 1.5rem !important;
    }
    #wrapper {
      padding: 1.5rem 3rem !important;
    }
  }
  @media (max-width: 710px) {
    #content > div {
      flex-direction: column !important;
      align-items: flex-start !important;
      margin-bottom: 50px !important;
      &:nth-child(2n) {
        flex-direction: column !important;
        img {
          padding-right: 0% !important;
        }
      }
      &:nth-child(2n + 1) {
        flex-direction: column !important;
        img {
          padding-left: 0% !important;
        }
      }
      .services-title {
        padding-bottom: 0 !important;
      }
    }
  }
  @media (max-width: 568px) {
    background-position: -90vw -20vh;
    background-size: 270vw;
  }
`

const Services = ({ siteTitle }) => {

  return (
    <StyledDiv>
      <div id="sticky-header">
        <GlobalHeading
          primary={"our services"}
          secondary={"plenty to choose from..."}
          subsection={"services"}
          flow={"flex-start"}
        />
      </div>

      <div id="wrapper" onScroll={() => console.log("")}>
        <div id="sticky">
          <div>
            <h1 className="services-title">Web applications</h1>
          </div>{" "}
          <div>
            <h1 className="services-title">Business Software</h1>
          </div>{" "}
          <div>
            <h1 className="services-title">Hardware solutions</h1>
          </div>{" "}
          <div>
            <h1 className="services-title">Internet of Things</h1>
          </div>{" "}
          <div>
            <h1 className="services-title">Custom UI/UX Design</h1>
          </div>{" "}
          <div>
            <h1 className="services-title">Consulting and Support</h1>
          </div>
        </div>
        <section className="content" id="content">
          <div>
            <img src={pic01} alt="service_icon" />
            <article>
              <h3 className="services-title">Web applications</h3>
              <p>
                In today’s digital world, the face of your business is your
                website. Having your website designed, administered and
                maintained by Nunkki can help you to reach a wider audience,
                increase your efficiency and increase your profitability.
              </p>
            </article>
          </div>
          <div>
            {" "}
            <img src={pic02} alt="service_icon" />
            <article>
              <h3 className="services-title">Business Software</h3>{" "}
              <p>
                Whether you need your custom-built software to track your
                inventory, to manage payroll, or to monitor an assembly line,
                Nunkki is here to build if for you. Our custom-built software
                can help you reduce your operational costs by increasing your
                productivity and efficiency.
              </p>{" "}
            </article>
          </div>
          <div>
            <img src={pic03} alt="service_icon" />
            <article>
              <h3 className="services-title">Hardware solutions</h3>{" "}
              <p>
                Nunkki has the know-how to build electronic solutions from the
                ground up, including schematic and PCB designs, analogue and
                digital circuit designs, and embedded electronics.
              </p>{" "}
            </article>
          </div>
          <div>
            <img src={pic04} alt="service_icon" />

            <article>
              {" "}
              <h3 className="services-title">Internet of Things</h3>
              <p>
                Today’s world expects every imaginable product to have
                connectivity. Maybe your latest product is a coffee machine and
                you want to give your customers the capabilities to make coffee
                with the use of an app on a mobile device. Nunkki has the
                knowledge and software capabilities to have all types of
                imaginable products connected to the internet.
              </p>{" "}
            </article>
          </div>
          <div>
            <img src={pic05} alt="service_icon" />
            <article>
              <h3 className="services-title">Custom UI/UX Design</h3>
              <p>
                First impression matters. Your business requires an original
                style guide and a pattern library to project your unique image
                onto your products and services. We can offer you a complete
                application design prototype and a predefined set of design
                rules.
              </p>{" "}
            </article>
          </div>
          <div>
            <img src={pic06} alt="service_icon" />
            <article>
              <h3 className="services-title">Consulting and Support</h3>
              <p>
                Whether you need ideas on how to digitise your business to cut
                costs, increase profits, streamline your company, or your
                looking to rely on someone to maintain your software and
                applications and to assure it’s there when you need them most.
                Then contact us, Nunkki won’t leave you in the dark!
              </p>{" "}
            </article>
          </div>
        </section>
      </div>
      <div className="extra" />
    </StyledDiv>
  )
}

Services.propTypes = {}

Services.defaultProps = {}

export default Services
