import React, { useState } from "react"
import TextField from "@material-ui/core/TextField"
import FormLabel from "@material-ui/core/FormLabel"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import styled from "styled-components"
import GlobalHeading from "./globalHeading"
import fourArrows from "../images/four_arrows.png"
import tree from "../images/tree.png"
import bottomCircles from "../images/bottom_circles.png"
import NetlifyForm from "react-netlify-form"

import { colors, boxShadow } from "./globalStyle"

const StyledForm = styled.form`


  padding: 5rem 7rem;
  overflow: hidden;
  margin: 0;
  padding-bottom: 0;
  background-image: url(${bottomCircles});
  background-position: 10vw -25vh;
  background-size: 130vw;
  background-repeat: no-repeat;


  .aditional-msg {
    color: ${colors.blueGrey};
    text-align: left;
    font-family: Maven Pro;
    font-weight: 500;
    font-size: 0.8rem;
    padding: 0;
    padding-left: 4rem;
    margin: 0;
  }
  main {
    margin-top: 4rem;
    margin-left: 3rem;
    .MuiFormLabel-root {
      font-family: Montserrat;
      font-size: 0.8rem;
    }
    .MuiInputBase-root {
      margin: 1.5rem 0;
    }

    width: 60vw;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    div {
      width: 100%;
      margin-bottom: 1rem;

      input {
        font-family: Montserrat;
      }
      .MuiInputLabel-shrink {
        transform: translate(0, 1.5px) scale(1);
      }
      .MuiInput-underline::after {
        border-bottom: 2px solid ${colors.primary};
      }
      .MuiFormLabel-root.Mui-focused {
        color: ${colors.primary};
      }
      .MuiFormLabel-root.Mui-error {
        color: ${colors.primary};
      }
      p {
        font-size: 0.6rem;
        font-family: Montserrat;
        margin: 0;
        text-align: right;
        margin-top: -10px;
        color: ${colors.error};
      }
    }
    .button-section {
      margin: 1rem 0rem;
      button {
        cursor: pointer;
        background: ${colors.primary};
        border: 0;
        margin: 0.7rem;
        margin-left: 0;
        border-radius: 31px;
        color: ${colors.white};
        font-family: Montserrat;
line-height: 0.85rem;
        font-size: 0.7rem;
        letter-spacing: 0.07rem;
        padding: 0.45rem 1.5rem;
        box-shadow: ${boxShadow.small};
        text-transform: none;
        padding: 0.5rem 1.3rem;
      }
    }
    .MuiCheckbox-colorSecondary.Mui-checked {
      color: ${colors.primary};
      &:hover {
        background-color: rgba(22, 177, 255, 0.08);
      }
    }
    .MuiIconButton-colorSecondary {
      &:hover {
        background-color: rgba(22, 177, 255, 0.08);
      }
    }
    .MuiFormControlLabel-label {
      font-family: Montserrat;
    }
    .agree-section {
      display: flex;
      align-items: center;
      margin: 2rem 0rem;
      margin-left: 0.5rem;

      label {
        margin-right: 0.25rem;
        span {
          font-size: 0.8rem;
          color: rgba(0, 0, 0, 0.54);
        }
        .MuiIconButton-label {
          color: ${colors.primary};
        }
      }
      h4 {
        margin: 0;
        font-size: 0.8rem;
        color: ${colors.primary};
        padding: 2px;
        border-bottom: 1px solid currentColor;
        cursor: pointer;
      }
    }
    .error-msg {
      font-size: 0.6rem;
      font-family: Maven Pro;
      margin: 0;
      text-align: left;
      margin-top: 10px;
      line-height: 0.65rem;
      color: ${colors.error};
    }
    .submit {
      margin: 1rem 0rem;
      button {
        cursor: pointer;
        color: ${colors.white};
        background: ${colors.primary};
        padding: 0.7rem 1.6rem;
        font-family: Montserrat;
        font-size: 0.9rem;
        font-weight: bold;
        letter-spacing: 0.09rem;
        border: 0;
        border-radius: 31px;
        box-shadow: ${boxShadow.small};
      }
    }
  }

  .four-arrows {
    height: 80vh;
    position: absolute;
    right: 0;
    margin-top: -15rem;
    z-index: 0;
    opacity: 0.5;
  }

  .trees {
    //  -webkit-filter: drop-shadow(1px 1px 99px rgba(13, 41, 65, 0.05));
    //  filter: drop-shadow(1px 1px 99px rgba(13, 41, 65, 0.05));
    // background-repeat: repeat-x;
    // background-image: url(${tree});
    // background-size: 22vh;
    // height: 20vh;
    // margin: 0 -7rem;

    height: 35vh;
    margin:  0 -7rem ;
    margin-bottom: -0.5rem;
    display :flex;
    justify-content: space-evenly;
    width: 100vw;

    img {
margin: 0;
margin-right: -4rem;
-webkit-filter: drop-shadow(1px 1px 99px rgba(13, 41, 65, 0.1));
filter: drop-shadow(1px 1px 99px rgba(13, 41, 65, 0.1));
     
    }
  }

  @media (max-width: 1024px) {
    padding: 3rem 5rem !important;
    padding-bottom: 0rem !important;
    min{
      width: 100% !important;
    }
  }
  @media (max-width: 910px) {
    padding: 3rem 3rem !important;
    padding-bottom: 0rem !important;
  }
  @media (max-width: 850px) {
    .submit {
      button {
        padding: 0.5rem 1.2rem !important;
        font-size: 0.7rem !important;
      }
    }
  }
  @media (max-width: 768px) {
    padding: 3rem 1rem !important;
    padding-bottom: 0rem !important;
    background-position: 5vw -15vh !important;
    background-size: 150vw  !important;

    .agree-section { 
      width: 90vw !important;
      max-width: 90vw !important;
    }
  }
  @media (max-width: 550px) {
    .four-arrows{
      display: none;
    }

    // .submit {
    //   button {
    //     padding: 0.4rem 1rem !important;
    //     font-size: 0.6rem !important;
    //   }
    // }
  }
  @media (max-width: 420px) {
    background-position: -5vw -5vh !important;
    background-size: 180vw  !important;
    .agree-section { 
        width: 60vw !important;
        max-width: 60vw !important;
        display: flex;
        flex-direction: column !important;
        align-items: flex-start !important;
        align-self: flex-start !important;
        margin: 0 !important;
        margin-bottom: 1rem !important;

     }
    }

`

const SmallBtn = styled(Button)`
  color: ${props =>
    !props.checkedbtn ? `${colors.classicGrey} !important` : `${colors.white}`};
  background: ${props =>
    !props.checkedbtn ? `${colors.white} !important` : `${colors.primary}`};
  box-shadow: ${props =>
    !props.checkedbtn ? `${boxShadow.big} !important` : `${boxShadow.small}`};
`

export default function BasicTextFields() {
  const [interestedAreas, setInterestedAreas] = useState({})
  const [textbox, setTextbox] = useState({})
  const [error, setError] = useState({})
  const [agree, setAgree] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [disabled, setDisabled] = useState(true)

  const checkAndSend = () => {
    let valid = {}
    let validArr = []
    setOfFields.map(field => {
      if (!Object.keys(textbox).includes(field) || textbox[field].length === 0) {
        valid[field] = true
        validArr.push(field)
      } else {
        valid[field] = false
      }
    })
    setError(valid)
    if (validArr.length === 0) {
      setDisabled(false)
    } else {
      setDisabled(true)
      setErrorMsg("Please fill in all required fields")
    }
    if (!agree) {
      setErrorMsg(
        "Please agree to all the terms and conditions before placing the order"
      )
      setDisabled(true)
    }
  }
  const setOfBtns = [
    "Web applications",
    "Business Software",
    "Hardware solutions",
    "Internet of Things",
    "Custom UI/UX Design",
    "Branding",
    "Other",
  ]
  const btnSection = setOfBtns.map(btn => (
    <SmallBtn
      key={btn}
      checkedbtn={
        Object.keys(interestedAreas).includes(btn) && interestedAreas[btn]
          ? 1
          : 0
      }
      onClick={() => {
        setInterestedAreas({
          ...interestedAreas,
          [btn]: !interestedAreas[btn],
        })
      }}
    >
      {btn}
    </SmallBtn>
  ))
  const setOfFields = [
    "First Name",
    "Last Name",
    "Company",
    "Email",
    "Project description",
  ]
  const fieldsSection = setOfFields.map(field => (
    <TextField
      helperText={error[field] ? "* This field is required." : ""}
      error={error[field]}
      key={field}
      id={field}
      name={field}
      type="text"
      label={field}
      value={textbox[field]}
      onChange={e => {
        setTextbox({ ...textbox, [field]: e.target.value })
        setError({
          ...error,
          [field]: e.target.value.length > 0 ? false : true,
        })
        setErrorMsg("")
      }}
    />
  ))

  return (
    <StyledForm noValidate autoComplete="off">
      <GlobalHeading
        primary={"Let’s talk business"}
        secondary={"say hello, maybe something great will happen"}
        subsection={"contact form"}
      />
      <img alt="arrow" className="four-arrows" src={fourArrows} />

      <NetlifyForm name="contact-form-nunkki-com">
        {({ loading, errorPlugin, success }) => (
          <div>
            {loading && <div className="aditional-msg">Loading...</div>}
            {errorPlugin && (
              <div className="aditional-msg">
                Something went wrong, try it later.
              </div>
            )}
            {success && (
              <div>
                <main>
                  {fieldsSection}
                  <TextField
                    style={{ display: "none" }}
                    key={"abc00"}
                    id={"abc00"}
                    name={"interestedAreas"}
                    type="text"
                    value={Object.keys(interestedAreas).filter(
                      key => interestedAreas[key] === true
                    )}
                  />
                  <FormLabel style={{ fontSize: ".8rem", paddingTop: "2rem" }}>
                    I am interested in
                  </FormLabel>
                  <section className="button-section">{btnSection}</section>
                  <section className="agree-section">
                    {" "}
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="agree"
                          checked={agree}
                          onChange={() => {
                            setAgree(!agree)
                            setErrorMsg("")
                          }}
                          value={agree}
                        />
                      }
                      label="I agree with the Nunkki"
                    />
                    <a href="/terms-and-conditions" target="_blank">
                      {" "}
                      <h4>Terms and Conditions</h4>
                    </a>
                  </section>
                  <section className="submit">
                    {disabled && (
                      <Button type="button" onClick={checkAndSend}>
                        SEND MESSAGE
                      </Button>
                    )}
                    {!disabled && <Button type="submit">SEND MESSAGE</Button>}
                    <p className="MuiFormHelperText-root Mui-error error-msg">
                      {errorMsg}
                    </p>
                  </section>
                </main>

                <div className="aditional-msg">
                  Thank you for getting in touch and being awesome!
                </div>
              </div>
            )}
            {!loading && !success && (
              <main>
                {fieldsSection}
                <TextField
                  style={{ display: "none" }}
                  key={"abc00"}
                  id={"abc00"}
                  name={"interestedAreas"}
                  type="text"
                  value={Object.keys(interestedAreas).filter(
                    key => interestedAreas[key] === true
                  )}
                />
                <FormLabel style={{ fontSize: ".8rem", paddingTop: "2rem" }}>
                  I am interested in
                </FormLabel>
                <section className="button-section">{btnSection}</section>
                <section className="agree-section">
                  {" "}
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="agree"
                        checked={agree}
                        onChange={() => {
                          setAgree(!agree)
                          setErrorMsg("")
                        }}
                        value={agree}
                      />
                    }
                    label="I agree with the Nunkki"
                  />
                  <a href="/terms-and-conditions" target="_blank">
                    {" "}
                    <h4>Terms and Conditions</h4>
                  </a>
                </section>
                <section className="submit">
                  {disabled && (
                    <Button type="button" onClick={checkAndSend}>
                      SEND MESSAGE
                    </Button>
                  )}
                  {!disabled && <Button type="submit">SEND MESSAGE</Button>}
                  <p className="MuiFormHelperText-root Mui-error error-msg">
                    {errorMsg}
                  </p>
                </section>
              </main>
            )}
          </div>
        )}
      </NetlifyForm>

      <div className="trees">
        {" "}
        <img alt="tree" src={tree} />
        <img alt="tree" src={tree} />
        <img alt="tree" src={tree} />
        <img alt="tree" src={tree} />
        <img alt="tree" src={tree} />
        <img alt="tree" src={tree} />
      </div>
    </StyledForm>
  )
}
