import React from "react"
import { hot } from "react-hot-loader/root"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/banner"
import Slider from "../components/slider"
import Form from "../components/form"
import Footer from "../components/footer"
import Services from "../components/services"
import AboutUs from "../components/aboutUs"
import Contact from "../components/contact"

const IndexPage = () => (
  <Layout>
    <SEO
      title="NUNKKI"
      keywords={[
        "nunkki",
        "webpage",
        "app development",
        "webpages",
        "hardware development",
        "e-commerce",
        "ui ux",
        "webpage template",
        "digital agency",
      ]}
    />
    <section id="banner">
      <Banner />
    </section>

    <section id="inspirations">
      <Slider />
    </section>
    <section id="services">
      <Services />
    </section>
    <section id="who we are">
      <AboutUs />
    </section>
    <section id="contact">
      <Contact />
    </section>
    <section id="say hello">
      <Form />
    </section>
    <Footer />
  </Layout>
)

export default hot(IndexPage)
