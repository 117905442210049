import React from "react"
import GlobalHeading from "./globalHeading"
import { colors, boxShadow } from "./globalStyle"
import twoArrows from "../images/two_arrows.png"
import styled from "styled-components"

const StyledDiv = styled.div`
  padding: 5rem 7rem;
  padding-bottom: 0;
  font-family: Montserrat;

  .mainDiv {
    padding-top: 4rem;
    display: flex;
    justify-content: flex-start;
    align-items: space-between;
    height: auto;
    & > div {
      margin-right: 1.45rem;
    }
  }

  .main {
    display: flex;
    flex-wrap: wrap;

    .contact-box {
      z-index: 3;
      background: ${colors.white};
      box-shadow: ${boxShadow.new};
      border-radius: 27px;
      width: 16rem;
      height: auto;
      padding: 2rem;
      margin-left: 2.5rem;
      margin-top: 0;
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      h3 {
        font-family: Baloo;
        margin-bottom: -0.3rem;
        font-size: 1.5rem;
      }
      p {
        margin: 0;
        font-size: 0.8rem;
        display: inline !important;
      }
      .secondary {
        font-size: 1rem;
        color: ${colors.lightGrey};
        margin-bottom: 0.5rem;
      }
    }
  }
  .img-section {
    display: flex;
    flex-wrap: wrap;
    height: 40vh;
    img {
      height: 25vh;
      margin-right: 1rem;
    }
  }

  .arrows {
    z-index: 1;
    background-image: url(${twoArrows});
    background-position: 0 0;
    -webkit-filter: drop-shadow(1px 1px 99px rgba(13, 41, 65, 0.05));
    filter: drop-shadow(1px 1px 99px rgba(13, 41, 65, 0.05));
    background-repeat: round;
    background-size: 14vh;
    height: 7vh;
    margin-right: 7rem;
    margin-left: -7rem;
    margin-top: -1.5rem;
  }

  @media (max-width: 1024px) {
    padding: 3rem 5rem !important;
    padding-bottom: 0rem !important;
  }
  @media (max-width: 910px) {
    padding: 3rem 3rem !important;
    padding-bottom: 0rem !important;
  }
  @media (max-width: 768px) {
    padding: 3rem 1rem !important;
    padding-bottom: 0rem !important;
  }
  @media (max-width: 700px) {
    .mainDiv {
      padding-top: 2rem !important;
    }
    .main {
      .contact-box {
        padding: 1.5rem !important;
        width: 14rem !important;
      }
    }
  }
  @media (max-width: 330px) {
    .main {
      margin: 0 !important;
      .contact-box {
        padding: 1.5rem !important;
        width: 14rem !important;
        margin: 0.5rem auto !important;
      }
    }
  }
`
const Contact = ({ siteTitle }) => (
  <StyledDiv>
    <GlobalHeading
      primary={"contact"}
      secondary={"we offer global operations"}
      subsection={"meet us"}
      flow={"flex-start"}
    />
    <div className="mainDiv">
      <div className="main">
        <section className="contact-box">
          <h3>Martin Risko</h3>
          <p className="secondary">founder </p>
          <p>martin.risko@nunkki.com</p>
          <p>+421 915 977 584</p>
        </section>
        {/* <section className="contact-box">
          <h3>Michaela Risko</h3>
          <p className="secondary">developer & UX/UI </p>
          <p>michaela.risko@nunkki.com</p>
          <p>+421908 333 843</p>
        </section> */}
        {/* <section className="contact-box">
          <h3>Slovakia</h3>
          <p className="secondary">address</p>
          <p>Mirka Nespora 71 </p>
          <p>08001 Presov </p>
        </section> */}
      </div>
    </div>
    <section className="arrows"></section>
  </StyledDiv>
)

Contact.propTypes = {}

Contact.defaultProps = {}

export default Contact

/**
 *   <div className="img-section">
        <img src={pic01}></img>
        <img src={pic02}></img>
        <img src={pic03}></img>
        <img src={pic04}></img>
      </div>


              <section>
          <h3>Kosice</h3>
          <p className="secondary">office </p>
          <p>
            0123 Letna Kosice 5 <br />
            Slovakia
          </p>
        </section>

 */
