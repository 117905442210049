import React, { useState, useEffect } from "react"
import bannerLeftCircles from "../images/banner_left_circles.png"
import bannerRightCircles from "../images/banner_right_circles.png"

import { colors, boxShadow } from "./globalStyle"
import Scroll from "./scroll"
import styled, { keyframes } from "styled-components"
import { fadeInUp } from "react-animations"
import { Button } from "@material-ui/core"

const fadeInUpAnimation = keyframes`${fadeInUp}`

const FadeDiv = styled.div`
  animation: 0.2s ${fadeInUpAnimation};
`
const StyledImgDiv = styled.div`
  position: relative;
  width: 100%;
  top: 0 !important;
  z-index: 1;
  img {
    position: absolute;
    width: 100vw;
    top: 0;
  }
  .left-circle {
    left: 0;
    margin-left: -32vw !important;
  }
  .right-circle {
    right: 0;
    margin-top: -25vh;
    margin-right: -32vw !important;
  }

  @media (max-width: 550px) {
    width: 120% !important;
    img {
      width: 120vw !important;
    }
    .left-circle {
      left: 0;
      margin-top: 10vh;
    }
    .right-circle {
      right: 0;
      margin-top: -10vh !important;
    }
  }
  @media (max-width: 440px) {
    width: 150% !important;
    img {
      width: 150vw !important;
    }
    .left-circle {
      left: 0;
      margin-top: 25vh !important;
      margin-left: -42vw !important;
    }
    .right-circle {
      right: 0;
      margin-top: -5vh !important;
      margin-right: -10vw !important;
    }
  }
`

const Landing = styled.div`
  height: calc(100vh - 4.2rem) !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  section {
    margin-top: calc(100vh * 0.25);
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-family: Baloo;
      color: ${colors.blueGrey};
      font-size: 6rem;
      line-height: 5.5rem;
      font-weight: 900 !important;
    }
    p {
      color: ${colors.blueGrey};
      opacity: 0.6;
      text-align: center;
      font-family: Montserrat;
      padding: 0 2rem;
    }
    button {
      cursor: pointer;
      color: ${colors.white};
      background: ${colors.primary};
      padding: 0.7rem 1.6rem;
      font-family: Montserrat;
      font-size: 0.9rem;
      font-weight: bold;
      letter-spacing: 0.09rem;
      border: 0;
      border-radius: 31px;
      box-shadow: ${boxShadow.small};
      &:hover {
        background: ${colors.primary};
      }
    }
  }
  .row {
    display: flex;
    opacity: 0.5;
    .first-word {
      margin-right: 1.5rem;
    }
  }
  @media (max-width: 850px) {
    h1 {
      font-size: 4rem !important;
      line-height: 3rem !important;
    }
    button {
      padding: 0.5rem 1.2rem !important;
      font-size: 0.7rem !important;
    }
  }
  @media (max-width: 550px) {
    h1 {
      font-size: 3rem !important;
      line-height: 1.8rem !important;
    }
    p {
      font-size: 0.85rem;
    }
    button {
      padding: 0.4rem 1rem !important;
      font-size: 0.6rem !important;
    }
  }
  @media (max-width: 410px) {
    h1 {
      font-size: 2.4rem !important;
      line-height: 1.1rem !important;
    }
  }
  @media (max-width: 340px) {
    .row {
      display: flex;
      flex-direction: column;
      align-items: center;
      .first-word {
        margin-right: 0 !important;
      }
    }
  }
`
const Banner = ({ siteTitle }) => {
  const [view0, setView0] = useState(false)
  const [view1, setView1] = useState(false)
  const [view2, setView2] = useState(false)
  const [view3, setView3] = useState(false)
  const [view4, setView4] = useState(false)
  const array = [0, 1, 2, 3, 4, 5, 6, 7]

  useEffect(() => {
    array.forEach(function (obj, index) {
      setTimeout(function () {
        switch (index) {
          case 0:
            setView0(true)
            break
          case 1:
            setView1(true)
            break
          case 2:
            setView2(true)
            break
          case 3:
            setView3(true)
            break
          case 4:
            setView4(true)
            break
          default:
            break
        }
      }, 200 * (index + 1))
    })
  })

  return (
    <Landing>
      <StyledImgDiv>
        <FadeDiv>
          <img alt="circle" src={bannerRightCircles} className="right-circle" />
        </FadeDiv>
        <FadeDiv>
          <img alt="circle" src={bannerLeftCircles} className="left-circle" />
        </FadeDiv>
      </StyledImgDiv>
      <section>
        {view0 && (
          <FadeDiv>
            <h1>digitize</h1>
          </FadeDiv>
        )}
        <div className="row">
          {view1 && (
            <FadeDiv>
              <h1 className="first-word">your </h1>{" "}
            </FadeDiv>
          )}
          {!view2 && <h1 style={{ visibility: "hidden" }}> business</h1>}
          {view2 && (
            <FadeDiv>
              <h1> business</h1>{" "}
            </FadeDiv>
          )}
        </div>

        {view3 && (
          <FadeDiv>
            <p>
              software applications and hardware solutions ready to transform
              your business
            </p>
          </FadeDiv>
        )}
        {view4 && (
          <FadeDiv>
            <Scroll type="id" element="services">
              <Button>Our services</Button>
            </Scroll>
          </FadeDiv>
        )}
      </section>
    </Landing>
  )
}

Banner.propTypes = {}

Banner.defaultProps = {}

export default Banner
