import React, { useState, useEffect } from "react"
import { colors, boxShadow } from "./globalStyle"
import styled from "styled-components"
import GlobalHeading from "./globalHeading"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace"

const StyledDiv = styled.div`
  background: ${colors.white};
  box-shadow: ${boxShadow.medium};
  padding: 5rem 7rem;
  padding-bottom: 2rem;

  main {
    display: flex;
    justify-content: flex-end;
  }

  .MuiPaper-root {
    width: 80%;
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiExpansionPanel-root::before {
    background-color: ${colors.white};
  }
  .MuiExpansionPanelSummary-root {
    padding: 0;
  }
  .MuiExpansionPanelSummary-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .MuiExpansionPanelDetails-root {
    padding: 0;
  }
  .MuiButton-label {
    margin-left: -10px;
  }

  .text {
    font-family: Montserrat;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    h4 {
      font-weight: normal;
      text-align: right;
      font-size: 1.3rem;
      line-height: 2.5rem;
      padding-bottom: 1.2rem;
      margin: 0;
      span {
        margin-left: 74px;
      }
    }
    p {
      margin-top: -1rem;
      text-align: right;
      font-size: 1rem;
      line-height: 2.2rem;
    }
    .MuiIconButton-root {
      padding: 0;
    }
    button {
      align-self: flex-end;

      height: 1.8rem;
      width: 2.8rem;
      cursor: pointer;
     // border-radius: 24px;
      background: ${colors.white};
      color: ${colors.primary};
      border: 0;
     // box-shadow: ${boxShadow.big};

      svg {
        line-height: 2rem;
        padding: 0;
        font-size: 1.9rem;
      }
    }

    .Mui-expanded{
      button{
        transform: rotate(-90deg);

      }
    }
  }

  @media (max-width: 1024px) {
    padding: 3rem 5rem !important;
    padding-bottom: 1rem !important;
    .MuiPaper-root {
      width: 100% !important;
    }
  }
  @media (max-width: 910px) {
    padding: 3rem 3rem !important;
    padding-bottom: 1rem !important;
    .text {
      padding: 1 0 !important;
    }
    h4 {
      font-size: 1rem !important;
    }
    p {
      font-size: 0.9rem !important;
      line-height: 1.9rem !important;
    }
  }
  @media (max-width: 768px) {
    padding: 3rem 1rem !important;
    padding-bottom: 1rem !important;
    h4 {
      font-size: 1rem !important;
    }
    p {
      font-size: 0.7rem !important;
      line-height: 1.5rem !important;
    }
  }
  @media (max-width: 430px) {
    h4 {
      font-size: 0.9rem !important;
      line-height: 2rem !important;
    }
    p {
      font-size: 0.8rem !important;
      line-height: 1.5rem !important;
    }
    .MuiExpansionPanelSummary-content {
      h4 {
        font-size: 0.8rem !important;
        line-height: 2rem !important;
      }
    }
  }
`

const AboutUs = ({ siteTitle }) => {
  const [width, setWidth] = useState(0)
  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener("resize", updateWindowDimensions)

    return () => {
      window.removeEventListener("resize", updateWindowDimensions)
    }
  })

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth)
  }
  return (
    <StyledDiv>
      <GlobalHeading
        primary={"we are awesome"}
        secondary={"collaboration at it’s finest"}
        subsection={"about us"}
      />
      <main>
        <ExpansionPanel className="text">
          <ExpansionPanelSummary
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >
            <h4>
              From the beautiful country of Slovakia,
              {width > 350 && <br />}
              we present to you our company
            </h4>
            <button>
              <KeyboardBackspace style={{ transform: "rotate(180deg)" }} />
            </button>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <p>
              Although we offer a wide range of products, services and
              solutions, what we are really offering to you is business growth,
              increased sales, improved visibility and lower operational costs.
              Being small in size guarantees our customers a unique and
              individual relationship with us. We at Nunkki use the Agile
              framework to develop products, services and solutions. We
              periodically tough base with our customers during development
              process to ensure that our final product will not only meet but
              will also surpass customer expectations. After product delivery,
              our customers can also enjoy personalised support.
            </p>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </main>
    </StyledDiv>
  )
}

AboutUs.propTypes = {}

AboutUs.defaultProps = {}

export default AboutUs

