import React from "react"
import { colors } from "./globalStyle"
import styled from "styled-components"

const StyledDiv = styled.div`
  display: flex;
  flex-direction: ${props =>
    props.flow === "flex-start" ? "row-reverse" : "row"};
  justify-content: flex-end;
  align-items: center;
  .column {
    display: flex;
    flex-direction: column;

    .styled-heading {
      display: flex;
      flex-direction: row;
      justify-content: ${props =>
    props.flow === "flex-start" ? "flex-start" : "flex-end"};
      align-items: center;
      width: 100%;
      h1 {
        font-family: Baloo;
        font-weight: 900 !important;
        color: ${colors.black};
        margin: 0;
        margin-bottom: 0.2rem;
        text-align: ${props =>
    props.flow === "flex-start" ? "left" : "right"};
      }
      span {
        height: 1px;
        border-radius: 10px;
        border: 1px solid ${colors.blueGrey};
        background: ${colors.primary};
        width: 25vh;
        margin: 0 1rem;
      }
    }

    h2 {
      text-transform: uppercase;
      font-weight: 900 !important;
      color: ${colors.lightGrey};
      margin: 0;
      text-align: ${props => (props.flow === "flex-start" ? "left" : "right")};
    }
  }

  h3 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.55rem;
    letter-spacing: 0.05rem;
    color: ${colors.classicGrey};
    transform: rotate(-90deg);
    align-self: flex-end;
  }

  @media (max-width: 910px) {
    h1 {
      font-size: 1.6rem;
    }
    h2 {
      font-size: 1rem;
    }
    h3 {
      font-size: 0.4rem;
    }
    span {
      width: 10vh !important;
    }
  }
  @media (max-width: 668px) {
    h1 {
      font-size: 1.2rem;
    }
    h2 {
      font-size: 0.7rem;
    }
    h3 {
      font-size: 0.3rem;
    }
    span {
      width: 10vh !important;
    }
  }
  @media (max-width: 390px) {
    span {
      display: none;
    }
    h3 {
      opacity: 0.5 !important;
    }
  }
`

const GlobalHeading = ({ primary, secondary, subsection, flow }) => {
  return (
    <StyledDiv flow={flow}>
      <div className="column">
        <section className="styled-heading">
          {flow !== "flex-start" && <span />}
          <h1>{primary}</h1>
          {flow === "flex-start" && <span />}
        </section>
        <h2>{secondary}</h2>
      </div>

      <h3>{subsection}</h3>
    </StyledDiv>
  )
}

GlobalHeading.propTypes = {}

GlobalHeading.defaultProps = {}

export default GlobalHeading
