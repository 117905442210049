import React from "react"
import Scrollspy from "react-scrollspy"
import Scroll from "./scroll"
import logo from "../images/logoNunkki.png"

import MailOutline from "@material-ui/icons/MailOutline"
import SupervisorAccount from "@material-ui/icons/SupervisorAccount"

import { colors } from "./globalStyle"

import styled from "styled-components"

const StyledDiv = styled.div`
  font-family: "Montserrat" !important;
  padding: 0;
  padding-top: 2rem;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  z-index: 100;
  section {
    padding: 1rem 7rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: 1rem;
      cursor: pointer;

      svg {
        font-size: 0.95rem;
        margin-right: 0.3rem;
      }
      p {
        margin: 0;
      }
    }
  }
  .main {
    display: flex;
    justify-content: space-between;
    nav {
      ul {
        list-style-type: none;
        li {
          font-size: 0.8rem;
          margin-bottom: 0.5rem;
          button {
            color: ${colors.blueGrey};
            background: none;
            border: 0;
            cursor: pointer;
            img {
              max-width: 4rem;
            }
          }
        }
      }
    }
    article {
      p{
        margin: 0;
        text-align: left;
      }
      height: 100%;
      h4 {
        line-height: 1.5rem;
        font-size: 0.8rem;
      }
      .uppercase {
        text-transform: uppercase;
      }
    }
  }
  h1 {
    color: ${colors.blueGrey};
    font-size: 6rem;
    line-height: 5.5rem;
    font-weight: 900 !important;
  }
  p {
    color: ${colors.blueGrey};
    opacity: 0.6;
    text-align: center;
    font-size: 0.75rem;
  }
  @media (max-width: 1024px) {
    section {
      padding: 1rem 5rem !important;
    }
  }
  @media (max-width: 910px) {
    section {
      padding: 1rem 3rem !important;
    }
  }
  @media (max-width: 768px) {
    padding-top: 1rem !important;
    section {
      padding: 1rem 2rem !important;
    }
  }
  @media (max-width: 500px) {
    nav {
      display: none;
    }
  }
`
const Footer = ({ siteTitle }) => (
  <StyledDiv>
    <section>
      <Scroll type="id" element="say hello">
        <div>
          <MailOutline />
          <p>email us</p>
        </div>
      </Scroll>
      <Scroll type="id" element="contact">
        <div>
          <SupervisorAccount />
          <p>contacts</p>
        </div>
      </Scroll>
    </section>
    <section className="main">
      <nav>
        <Scrollspy items={["main"]} currentClassName="is-active" offset={-300}>
          <li>
            <Scroll type="id" element="inspirations">
              <button>inspirations</button>
            </Scroll>
          </li>
          <li>
            <Scroll type="id" element="services">
              <button>services</button>
            </Scroll>
          </li>
          <li>
            <Scroll type="id" element="who we are">
              <button>who we are</button>
            </Scroll>
          </li>
          <li>
            <Scroll type="id" element="contact">
              <button>contact</button>
            </Scroll>
          </li>
          <li>
            <Scroll type="id" element="say hello">
              <button>say hello</button>
            </Scroll>
          </li>
          <li>
            <Scroll type="id" element="banner">
              <button>
                <img alt="logo" src={logo} style={{ objectFit: "cover" }} />
              </button>
            </Scroll>
          </li>
        </Scrollspy>
      </nav>
      <article>
        <p>Address: </p>
        <h4 className="uppercase">
          Hlavna 50
          <br />
          08001 Presov <br />
          Slovakia
        </h4>
        <h4>
          info@nunkki.com
          <br />
          +421 915 977 584

        </h4>
      </article>
      <div style={{ visibility: "hidden" }}>
        <aside>
          <p>sign up</p>
        </aside>
      </div>
    </section>
  </StyledDiv>
)

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
